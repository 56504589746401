<template>
  <div class="accessories contentWrapper">
    <h1>Accessories</h1>
    <product-details
      label="TrackClip Pro"
      img="trackclip-pro-on-headphones.png"
      :itemId="139"
    >
      <p>
        The perfect TrackIR™ accessory that attaches to your over-the-ear
        headsets.
      </p>
      <ul>
        <li>Active LEDs for precise tracking</li>
        <li>USB power tap, with USB pass through</li>
        <li>Ultra light weight</li>
        <li>Range 2 to 5 feet (61 cm to 152 cm)</li>
        <li>2 position headset clip</li>
        <li>Attaches to headset bands above left ear</li>
        <li>
          Compatible with headset bands up to 1 1/4″ wide and up to 1/2″ thick
        </li>
      </ul>
    </product-details>
    <product-details
      class="trackClip"
      label="TrackClip"
      img="trackclip.png"
      :imgRight="true"
      :itemId="78"
    >
      <p>
        This lightweight black powder coated spring-steel clip has 3 built in
        reflectors. 3 7/8″ wide, 5″ long, 1 7/8″ tall.
      </p>
      <p>
        The TrackClip can be used with any standard baseball cap, or slipped
        onto the cross bar of your headset.
      </p>
    </product-details>
    <three-wide>
      <product-card
        label="TrackIR Hat - Black"
        :boldLabel="true"
        description="$15.00"
        img="trackir-hat-black.png"
        :itemId="40"
      ></product-card>
      <product-card
        label="TrackIR Visor - Black"
        :boldLabel="true"
        description="$15.00"
        img="trackir-visor-black.png"
        :itemId="111"
      ></product-card>
      <product-card
        label="TrackIR 5 Base"
        :boldLabel="true"
        description="$7.00"
        img="trackir-5-base@2x.png"
        :itemId="368"
      ></product-card>
    </three-wide>
    <three-wide>
      <product-card
        label="TrackIR Hat - White"
        :boldLabel="true"
        img="trackir-hat-white.png"
        :itemId="109"
      ></product-card>
      <product-card
        label="TrackIR Visor - White"
        :boldLabel="true"
        img="trackir-visor-white.png"
        :itemId="113"
      ></product-card>
    </three-wide>
  </div>
  <!-- END accessories -->
</template>
<script>
import { IMAGES_URL } from "@/constants/";
import ProductDetails from "@/components/ProductDetails";
import ThreeWide from "@/components/Layouts/ThreeWide";
import ProductCard from "@/components/ProductCard";
export default {
  name: "Accessories",
  computed: {
    imagesUrl: function () {
      return IMAGES_URL;
    },
  },
  components: {
    ProductDetails,
    ThreeWide,
    ProductCard,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.accessories {
  text-align: center;
  padding-bottom: 70px;
}
ul {
  padding-left: 20px;
}
.trackClip {
  margin-top: 100px;
  padding-bottom: 100px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
</style>
